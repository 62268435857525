import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Action, MediaObject, Modal } from '../../components';
import { useApi, useModal } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { TaxInfo } from '../compliance-documents/tax-info';
import { ContractDetailsContext } from './contract-details';
import { SignModal } from './sign-modal';
import { SignedBy } from './signed-by';
import './signer.scss';
import VerifyButton from '../../components/stripe/verify';
import { useProfileService } from '../../shared/services';
import { Role } from '../../constants';

export const Signer = ({
  contract,
  type,
}: {
  contract: any;
  type: Role.CLIENT | Role.CONTRACTOR;
}) => {
  const api = useApi();
  const modal = useModal();
  const toaster = useToaster();
  const history = useHistory();
  const contractContext = useContext(ContractDetailsContext);
  const { profile } = useProfileService();

  const [, setRenderForm] = React.useState<
    'w9' | 'w8ben' | 'w8bene' | undefined
  >();
  const [taxFormValue, setTaxFormValue] = React.useState<any>({});

  const isContractorVerified = React.useMemo(
    () => Boolean(profile?.identity_verified),
    [profile?.identity_verified],
  );

  const identityVerificationRequired = React.useMemo(() => {
    return (
      type === Role.CONTRACTOR &&
      Boolean(contractContext?.contract?.contractor_require_id_check || false)
    );
  }, [type, contractContext?.contract?.contractor_require_id_check]);

  const canSignContract = React.useMemo(() => {
    return (
      !identityVerificationRequired ||
      (identityVerificationRequired && isContractorVerified)
    );
  }, [identityVerificationRequired, isContractorVerified]);

  const openSignModal = React.useCallback(() => {
    let closeModal = () => {};
    modal
      .openModal((close, cancel) => {
        closeModal = close;
        return (
          <SignModal
            type={type}
            cancel={cancel}
            close={close}
            contract={contract}
          />
        );
      })
      .result.then(() => {
        contractContext.reload?.();
        closeModal();
        if (type === Role.CONTRACTOR) {
          history.push(`/contracts/${contract.id}/details`);
        }
      });
  }, [contract, contractContext, history, modal, type]);

  const submitTaxForm = React.useCallback(
    (form: any, value: any) => {
      if (value.signature_file) {
        const signature = new File([value.signature_file], 'signature.png');
        const formData = new FormData();
        formData.append('signature_file', signature);
        Object.keys(value)
          .filter((key) => key !== 'signature_file')
          .forEach((key) => {
            if (!isNil(value[key]) && value[key] !== '') {
              if (key === 'date_of_birth') {
                formData.append(
                  key,
                  (value[key] as Date).toISOString().split('T')[0],
                );
              } else if (key === 'tax_classification') {
                formData.append(
                  key,
                  String(value['tax_classification']).toUpperCase(),
                );
              } else if (key === 'business_type') {
                formData.delete(key);

                formData.append(key, value['business_type']?.value);
              } else if (key === 'ssn') {
                [
                  'social_security_number1',
                  'social_security_number2',
                  'social_security_number3',
                ].forEach((key, index) => {
                  formData.append(key, value['ssn'][index]);
                });
              } else if (key === 'ein') {
                [
                  'employer_identification_number1',
                  'employer_identification_number2',
                ].forEach((key, index) => {
                  formData.append(key, value['ein'][index]);
                });
              } else {
                formData.append(key, value[key]);
              }
            }
          });
        api
          .fetch(`/api/contractor/generate-${form}`, {
            method: 'POST',
            body: formData,
          })
          .then((res) => {
            modal.closeAllModals();
          })
          .catch(({ errors }) => {
            modal.closeAllModals();

            if (errors) {
              if (Array.isArray(errors)) {
                errors.forEach((message: string) =>
                  toaster.danger({ message }),
                );
              } else if (errors) {
                Object.keys(errors).forEach((error: string) =>
                  errors[error].forEach((message: string) =>
                    toaster.danger({ message }),
                  ),
                );
              }
            }
          });
      }
    },
    [api, modal, toaster],
  );

  const openTaxFormModal = React.useCallback(
    (form: any) => {
      modal.openModal(() => (
        <Modal className="pg-ComplianceModal" size="lg">
          <TaxInfo
            value={taxFormValue}
            renderForm={form}
            onSubmit={(value) => {
              setTaxFormValue(value);
              submitTaxForm(form, value);
            }}
            onClose={modal.closeAllModals}
          />
        </Modal>
      ));
    },
    [modal, submitTaxForm, taxFormValue],
  );

  const openModal = React.useCallback(() => {
    if (type === Role.CONTRACTOR) {
      api
        .get(`/api/contractor/contract/${contract.id}/check-tax-forms`)
        .then(({ data }) => {
          const forms = data['taxForms'];
          const form = Object.keys(forms).find((key) => forms[key]?.missing) as
            | 'w9'
            | 'w8ben'
            | 'w8bene'
            | undefined;
          // const form = 'w8bene';
          setRenderForm(form);
          if (form) {
            openTaxFormModal(form);
          } else {
            openSignModal();
          }
        })
        .catch(() => {
          setRenderForm('w9');
          openTaxFormModal('w9');
        });
    } else {
      openSignModal();
    }
  }, [api, contract?.id, openSignModal, openTaxFormModal, type]);

  React.useEffect(() => {
    if (window.location.search.indexOf('sign=true') > -1) {
      openModal();
    }
  }, [openModal]);

  return (
    <div className={classNames('pg-Signer')}>
      {!contract[`${type}Signer`] ? (
        <MediaObject
          className="pg-Signer__Content"
          media={
            <div className="pg-Signer__LeftContent">
              <div className="pg-Signer__Label">
                {type.charAt(0).toUpperCase() + type.slice(1)} signs here
              </div>
            </div>
          }
        >
          <div className="pg-Signer__Action">
            {Boolean(identityVerificationRequired) && (
              <VerifyButton
                as="button"
                className="mx-2"
                isVerified={Boolean(profile?.identity_verified || false)}
                verificationRequired={Boolean(
                  contract?.contractor_require_id_check || false,
                )}
                hasPendingVerification={Boolean(
                  profile?.has_pending_identity_verification || false,
                )}
              />
            )}

            <Action
              onClick={openModal}
              disabled={!canSignContract}
              tooltip={
                !canSignContract
                  ? 'You have to verify your identity before signing the contract'
                  : ''
              }
              theme={canSignContract ? 'primary' : 'warning'}
            >
              Review &amp; Sign
            </Action>
          </div>
        </MediaObject>
      ) : (
        <SignedBy
          type={type}
          name={contract[`${type}Signer`].name}
          email={contract[`${type}Signer`].email}
        />
      )}
    </div>
  );
};
